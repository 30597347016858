import React from "react";
import ColorDetectionApp from "./components/ColorDetectionApp";

function App() {
  return (
    <div className="App">
      <ColorDetectionApp />
    </div>
  );
}

export default App;
