import React, { useState, useEffect } from "react";
import { Palette } from "lucide-react";

const ColorDetectionApp = () => {
  const [colors, setColors] = useState({
    mainHeading: { color: "", opacity: 1 },
    secondaryHeading: { color: "", opacity: 1 },
    mainText: { color: "", opacity: 1 },
    secondaryText: { color: "", opacity: 1 },
    mainBackground: { color: "", opacity: 1 },
    secondaryBackground: { color: "", opacity: 1 },
    mainButton: { color: "", opacity: 1 },
    secondaryButton: { color: "", opacity: 1 },
  });
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    detectColors();
  }, []);

  const detectColors = () => {
    const getColor = (selector) => {
      const element =
        document.querySelector(selector) ||
        document.querySelector(selector.replace(".", ""));
      return element ? window.getComputedStyle(element).color : "";
    };

    const getBackgroundColor = (selector) => {
      const element = document.querySelector(selector);
      return element ? window.getComputedStyle(element).backgroundColor : "";
    };

    setColors({
      mainHeading: { color: getColor(".main-heading, h1"), opacity: 1 },
      secondaryHeading: { color: getColor(".secondary-heading"), opacity: 1 },
      mainText: { color: getColor(".main-text"), opacity: 1 },
      secondaryText: { color: getColor(".secondary-text"), opacity: 1 },
      mainBackground: {
        color: getBackgroundColor(".main-background"),
        opacity: 1,
      },
      secondaryBackground: {
        color: getBackgroundColor(".secondary-background"),
        opacity: 1,
      },
      mainButton: { color: getBackgroundColor(".main-button"), opacity: 1 },
      secondaryButton: {
        color: getBackgroundColor(".secondary-button"),
        opacity: 1,
      },
    });
  };

  const changeColor = (type, color, opacity) => {
    setColors((prevColors) => ({
      ...prevColors,
      [type]: {
        color,
        opacity: opacity !== undefined ? opacity : prevColors[type].opacity,
      },
    }));
    const elements = document.querySelectorAll(
      `.${type.replace(/([A-Z])/g, "-$1").toLowerCase()}`
    );
    const h1Elements =
      type === "mainHeading" ? document.querySelectorAll("h1") : [];
    [...elements, ...h1Elements].forEach((element) => {
      if (type.includes("Background") || type.includes("Button")) {
        element.style.backgroundColor = `rgba(${hexToRgb(color)}, ${opacity})`;
      } else {
        element.style.color = `rgba(${hexToRgb(color)}, ${opacity})`;
      }
    });
  };

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
          result[3],
          16
        )}`
      : null;
  };

  return (
    <div
      style={{ position: "fixed", bottom: "1rem", right: "1rem", zIndex: 1000 }}
    >
      <button
        onClick={() => setShowPanel(!showPanel)}
        style={{
          padding: "0.5rem",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <Palette style={{ marginRight: "0.5rem" }} />{" "}
        {showPanel ? "HIDE" : "SHOW"} COLOR PANEL
      </button>

      {showPanel && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "1rem",
            borderRadius: "0.5rem",
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            backdropFilter: "blur(10px)",
            marginTop: "1rem",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <div>
            <h3 style={{ color: "white" }}>COLOR CONTROLS</h3>
          </div>

          <div>
            {Object.entries(colors).map(([type, { color, opacity }]) => (
              <div
                key={type}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                }}
              >
                <label
                  htmlFor={type}
                  style={{ width: "150px", color: "white" }}
                >
                  {type
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .toUpperCase()}
                  :
                </label>
                <input
                  type="color"
                  id={type}
                  value={color}
                  onChange={(e) => changeColor(type, e.target.value, opacity)}
                  style={{ width: "50px", marginRight: "0.5rem" }}
                />
                <input
                  type="text"
                  value={color}
                  onChange={(e) => changeColor(type, e.target.value, opacity)}
                  style={{
                    width: "100px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "white",
                    border: "none",
                    padding: "0.25rem",
                    marginRight: "0.5rem",
                  }}
                />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={opacity}
                  onChange={(e) =>
                    changeColor(type, color, parseFloat(e.target.value))
                  }
                  style={{ width: "100px" }}
                />
                <span style={{ marginLeft: "0.5rem", width: "30px" }}>
                  {opacity.toFixed(1)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorDetectionApp;
